import React, {useState, useEffect, useContext} from 'react';
import { Link, useLocation } from "react-router-dom";
import cx from "classnames";
import styles from '../styles/root.module.scss';
import { FiX } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import { UserContext } from '../Contexts/UserContext';
import { loginEvent } from '../Contexts/events';

import logo from '../files/logo_black.png';

export default function MobileSideBar({close}) {

  const {apiUrl, siteId} = useContext(UserContext);
  const { t, i18n } = useTranslation();
  let location = useLocation();
  const [currTab, setCurrTab] = useState("/");

  const [categories, setCategories] = useState([]);

  const getAllCategoriesForSite = async (siteId) => {
    try {
      const response = await axios.get(`${apiUrl}/categories/${siteId}`);
  
      // console.log(response.data)
      setCategories(response.data);
    } catch (error) {
      console.error('Error fetching categories:', error);
      throw new Error('Failed to fetch categories');
    }
  };

  const logout = ()=>{
    sessionStorage.removeItem("tokenId");
    loginEvent.emit('loggedIn', false);
  }

  useEffect(()=>{
    getAllCategoriesForSite(siteId);
  },[]);

  useEffect(()=>{
    setCurrTab(location.pathname);
  },[location]);

  return (
    <div className={styles.mobileSideBarContainer} >
      <div className={styles.fixedHeader} >
        {/* <img src={logo} alt="hiya-portal" /> */}
        <p style={{fontFamily: 'Gobold', fontWeight: '600'}} >Alpha World</p>
        <FiX size={28} color="#888" onClick={()=> close()} />
      </div>
      <div className={styles.content} >
        <ul>
          <li>
            <Link to="/" onClick={()=> close()} className={cx({[styles.active]:currTab === '/'})}>
              <span>{t("navBar.home")}</span>
            </Link>
          </li>
          <li className={styles.categories} >
            <ul>
              <li>
                <span className={styles.categoriesTitle} >{ i18n.language === 'en' ? "categories" : "فئات"}</span>
              </li>
              {
                categories.length > 0 && categories.map((it, index)=>
                  <li>
                    <Link onClick={()=> close()} to={`/${it.id}`} className={cx({[styles.active]:decodeURIComponent(currTab) === `/${it.id}` })}>
                      <span>{i18n.language === "en" ? it.en_title : it.ar_title}</span>
                    </Link>
                  </li>
                )
              }
              {/* <li>
                <Link onClick={()=> close()} to={t('home.categories.turkish')} className={cx({[styles.active]:decodeURIComponent(currTab) === `/${t('home.categories.turkish')}` })}>
                  <span>{t('home.categories.turkish')}</span>
                </Link>
              </li>
              <li>
                <Link onClick={()=> close()} to={t('home.categories.chineses')} className={cx({[styles.active]:decodeURIComponent(currTab) === `/${t('home.categories.chineses')}` })}>
                  <span>{t('home.categories.chineses')}</span>
                </Link>
              </li>
              <li>
                <Link onClick={()=> close()} to={t('home.categories.english')} className={cx({[styles.active]:decodeURIComponent(currTab) === `/${t('home.categories.english')}` })}>
                  <span>{t('home.categories.english')}</span>
                </Link>
              </li>
              <li>
                <Link onClick={()=> close()} to={t('home.categories.french')} className={cx({[styles.active]:decodeURIComponent(currTab) === `/${t('home.categories.french')}` })}>
                  <span>{t('home.categories.french')}</span>
                </Link>
              </li> */}
            </ul>
          </li>
          {/* <li>
            <Link onClick={()=> close()} className={cx({[styles.active]:currTab === '/about'})}>
              <span>{t("navBar.about")}</span>
            </Link>
          </li> */}
          <li>
            <Link onClick={()=> close()} to="/categories" className={cx({[styles.active]:currTab === '/more'})}>
              <span>{t("navBar.more")}</span>
            </Link>
          </li>
          <li>
            <Link onClick={()=> logout()} to="/">
              <span>{t("navBar.logout")}</span>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  )
}