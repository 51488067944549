import React, {useState, useEffect, useContext} from 'react';
import styles from '../styles/modal.module.scss';
import { FiChevronLeft, FiSearch, FiPlay, FiX } from 'react-icons/fi';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

import { UserContext } from '../Contexts/UserContext';

function VideoContainer({item, openVideoModal}){

  const { i18n } = useTranslation();
  const [thumbnail, setThumbnail] = useState('');
  const videoId = item.video_url.split('/').pop();

  const fetchThumbnail = async () => {
    try {
      const response = await axios.get(`https://vimeo.com/api/v2/video/${videoId}.json`);
      // console.log(response.data[0]);
      setThumbnail(response.data[0].thumbnail_large+".jpg");
    } catch (error) {
      console.error('Error fetching Vimeo thumbnail:', error);
    }
  };

  useEffect(() => {
    fetchThumbnail();
  }, []);

  return(
    <div className={styles.videoWrapper} onClick={()=> openVideoModal({en_title: item.en_title, ar_title: item.ar_title, id: videoId}) } >
      <div className={styles.videoImg_phone} >
        <img src={thumbnail} style={{height: '100%', width: '100%', objectFit: "cover", borderRadius: 12}} />
        <div className={styles.smallVideoDarkOverlay} >
          <FiPlay color="#fff" size={36} />
        </div>
      </div>
      <p className={styles.videoTitle_phone} >
        { i18n.language === 'en' ? item.en_title : item.ar_title }
      </p>
    </div>
  )
}

export default function SearchModal({isOpen, close}) {

  const {apiUrl, siteId} = useContext(UserContext);
  const { i18n } = useTranslation();

  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [videos, setVideos] = useState([]);

  const closeModal = ()=>{
    setError(null);
    setSearchQuery("");
    setVideos([]);
    close();
  }
  const closeWithOpenVideo = (video)=>{
    setError(null);
    setSearchQuery("");
    setVideos([]);
    close(video);
  }

  const handleSearch = async () => {
    try {
      const response = await axios.get(`${apiUrl}/search`, {
        params: {
          q: searchQuery,
          lang: i18n.language,
          site: siteId
        },
      });

      setVideos(response.data);
      console.log(response.data);
      setError(null);
    } catch (error) {
      console.log(error);
      setError(error.response ? error.response.data.error : 'Internal server error');
      setVideos([]);
    }
  };

  useEffect(()=>{
    if(searchQuery.length > 0){
      handleSearch();
    }else{
      setVideos([]);
    }
  },[searchQuery]);

  if(isOpen){
    return (
      <div className={styles.modal} >
        <div className={styles.Searchcontent} >
          <div className={styles.searchHeader} >
            <input
              type='text'
              className={styles.searchInput}
              value={searchQuery}
              onChange={(e)=> setSearchQuery(e.target.value) }
            />
            <FiSearch size={22} className={styles.searchIcon} />
            <div className={styles.searchBackBtn} onClick={()=> closeModal() }  >
              <FiX size={28} strokeWidth={2.5} />
            </div>
          </div>
          <div className={styles.searchVideos} >
            {
              videos.length > 0 && videos.map((it)=>
                <VideoContainer key={it.id} item={it} openVideoModal={closeWithOpenVideo} />
              )
            }
          </div>
        </div>
        <div className={styles.bg} onClick={()=> closeModal() } />
      </div>
    )
  }else{
    return (
      null
    )
  } 

}