import React, {useContext, useState, useRef, useEffect} from 'react';
import styles from '../styles/videos.module.scss';
import { FiChevronLeft, FiChevronRight, FiPlay } from 'react-icons/fi';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import Modal from './Modal';

import { UserContext } from '../Contexts/UserContext';

function VideoContainer({item, openVideoModal}){

  const { i18n } = useTranslation();
  const [thumbnail, setThumbnail] = useState('');
  const videoId = item.video_url.split('/').pop();

  const fetchThumbnail = async () => {
    try {
      const response = await axios.get(`https://vimeo.com/api/v2/video/${videoId}.json`);
      // console.log(response.data[0]);
      setThumbnail(response.data[0].thumbnail_large+".jpg");
    } catch (error) {
      console.error('Error fetching Vimeo thumbnail:', error);
    }
  };

  useEffect(() => {
    fetchThumbnail();
  }, []);

  return(
    <div className={styles.video} key={item.id.toString()} >
      <div className={styles.thumbnailWrapper} onClick={()=> openVideoModal({en_title: item.en_title, ar_title: item.ar_title, id: videoId}) } >
        <img src={thumbnail} style={{height: '100%', width: '100%', objectFit: "cover", borderRadius: 12}} />
        <div className={styles.videoDarkOverlay} >
          <FiPlay color="#fff" size={36} />
        </div>
      </div>
      <p className={styles.videoTitle} >
        { i18n.language === 'en' ? item.en_title : item.ar_title }
      </p>
    </div>
  )
}

export default function Videos() {

  let { cat } = useParams();
  let location = useLocation();
  const navigate = useNavigate();
  const {apiUrl, siteId} = useContext(UserContext);
  const topRef = useRef(null);
  const { t, i18n } = useTranslation();

  const [videos, setVideos] = useState([]);
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const [modalData, setModalData] = useState({});
  const [category, setCategory] = useState({});

  const openVideoModal = (item)=>{
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    setIsVideoModalOpen(true);
    setModalData(item);
  }
  const closeVideoModal = ()=>{
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
    setIsVideoModalOpen(false);
    setModalData({});
  }

  const getCatById = ()=>{
    axios.get(`${apiUrl}/category/${cat}`)
    .then(response => {
      setCategory(response.data);
    })
    .catch(error => {
      console.error(error);
    });
  }

  const getCategoryVideos = async () => {
    let url = `${apiUrl}/filter/`;
    url += `${siteId}`;
    url += `/${cat}`;

    console.log(url);

    try {
      axios.get(url)
      .then((response) => {
        setVideos(response.data);
      })
      .catch((err) => {
        console.log(err.message);
      });

    } catch (error) {
      console.error(error);
    }
  };

  useEffect(()=>{
    if(cat && apiUrl){
      window.scrollTo(0, topRef.current.offsetTop);
      getCategoryVideos();
      getCatById();
    }
  },[location.pathname, cat, apiUrl]);

  return (
    <div className={styles.container} style={{direction: i18n.language === 'en' ? 'ltr' : 'rtl'}} ref={topRef} >
      <div className={styles.header} onClick={() => navigate(-1)} >
        {
          i18n.language === 'en'
          ? <FiChevronLeft size={38} color='#fff' className={styles.backBtn} />
          : <FiChevronRight size={38} color='#fff' className={styles.backBtn} />
        }
        <p>{ i18n.language === 'en' ? category.en_title : category.ar_title }</p>
      </div>
      <div className={styles.videosWrapper} >
        { videos.length > 0 && videos.map((it)=>
          <VideoContainer key={it.id} item={it} openVideoModal={openVideoModal} />
        ) }
      </div>
      <Modal isOpen={isVideoModalOpen} close={closeVideoModal} title={ i18n.language === "en" ? modalData.en_title : modalData.ar_title} >
        <div style={{
          width: '100%', 
          height: window.innerWidth < 642 ? window.innerHeight*0.3 : window.innerHeight*0.5
        }} >
          <iframe 
            src={`https://player.vimeo.com/video/${modalData.id}?byline=0&portrait=0&title=0&fullscreen=1`} 
            title={modalData.en_title}
            style={{ width: '100%', height: '100%', border: 'none' }}
            frameborder="0" 
            allowfullscreen="allowfullscreen" 
            mozallowfullscreen="mozallowfullscreen"
            msallowfullscreen="msallowfullscreen" 
            oallowfullscreen="oallowfullscreen" 
            webkitallowfullscreen="webkitallowfullscreen"
            >
          </iframe>
        </div>
      </Modal>
    </div>
  )
}
